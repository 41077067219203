@import "../../variables";



.connect_flex {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100vh;
    align-items: center;
    background-color: $bleu-canard-transparent;
    padding-bottom: 100px;
    img {
        width: 50%;
        padding: 50px 0; }
    button {
        padding: 15px 20px;
        background-color: #0b57bb;
        color: white;
        border-radius: 10px;
        font-weight: 700;
        border: none;
        font-size: 17px;
        transition: 0.9s;
        &:hover {
           background-color: #093268 {
            cursor: pointer; } } } }


