@import "../../variables";






#home {
    height: 100%; }

html {
  position: relative;
  height: 100%; }

body {
  position: relative;
  height: 100%;
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0; }

html, body {
  position: relative;
  height: 100%; }

#home {
  display: flex;
  justify-content: center;
  align-items: center; }

.swiper {
  width: 240px;
  height: 320px; }

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  height: auto; }



.carte {
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 50vh;
    padding: 5px; }

.btn_fav {
    .d-flex {
        align-items: end;
        justify-content: center;
        height: 25vh;
        button {
            margin: 20px;
            padding: 10px;
            border: none;
            border-radius: 50px;
            transition: 0.5s;
            &:hover {
                background: grey; }
            img {
                width: 5vw; } } } }

.swiper-3d .swiper-slide-shadow {
    background-color: #ffffff00; }

.swiper_card {
    margin-top: 20px; }
