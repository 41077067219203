@import "../../variables";



header {
    background-color: $bleu-canard;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 1rem 0;
    width: 100vw;
    img {
        width: 50px;
        height: 100%; }
    .logo_inspy {
        width: 100px; } }
