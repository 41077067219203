@import "../../variables";


.section_haut {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px 0;
    font-size: 20px;
    font-weight: 500;
    img {
        width: 30%; } }

.section_settings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50vh;
    .button_settings {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 30vh; }
    .info_settings {
        a {
            display: block;
            text-align: center; } } }










