@import "../../variables";






.header-profil {
    padding: 5% 0;
    .d-flex {
        align-items: center;
        justify-content: space-evenly;
        width: 100%; }
    img {
        width: 30%; }
    .button-add {
        width: 10%;
        position: relative;
        top: 40px;
        right: 45px; }
    .border-input {
        padding: 20px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.14);
        border-radius: 5px;
        .d-flex {
            align-items: center;
            input {
                margin: 5px; } } }
    .banniere {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        width: 50%;
        padding: 10px 10px;
        margin: 1rem auto;

        img {
            width: 15%; }
        button {
            font-size: 17px;
            height: 60%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin: auto;
            padding: auto;
            background-color: white;
            border: none; } } }

.profile-champ {
    padding-bottom: 20%;
    width: 85vw;
    margin: auto;
    .d-flex {
        flex-direction: column;
        input, textarea {
            margin: 0.5em 0;
            padding: 5px;
            border: none;
            background: #FFFFFF;
            box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            color: gray; }
        .text-zone {
            padding: 5px;
            width: 100%;
            height: 100px;
            resize: none; } }
    .button-save {
        padding: 20px 0;
        align-items: center; }
    p {
        text-align: center; } }



