@import "../../variables";

footer {
    display: flex;
    position: fixed;
    bottom: 0;
    background-color: $bleu-canard;
    justify-content: center;
    width: 100vw;
    padding: 1rem 0;
    img {
        width: 100px;
        padding: 0 1.5em; } }



