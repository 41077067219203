
@import "variables";


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }


.button-canard {
    padding: 10px 15px;
    border: none;
    background-color: $bleu-canard;
    color: white;
    border-radius: 10px;
    font-weight: 700;
    width: 100%; }

.d-flex {
    display: flex; }

.w100 {
    width: 100%; }

a {
    text-decoration: none;
    color: #000000; }
