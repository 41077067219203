@import "../../variables";


.img_entreprise {
    height: 50vh;
    width: 100vw;
    img {
        filter: invert(100%);
        -webkit-filter: invert(100%);
        width: 35px; }
    .cross {
        position: absolute;
        right: 10px;
        top: 10px; }
    .arrow {
        position: relative;
        transform: rotate(90deg);
        top: 85%;
        left: 45%; } }

.info {
    padding: 10% 1% 25% 1%;
    img {
        width: 80%;
        margin: auto;
        display: block; }
    a {
        font-weight: 500;
        font-size: 25px;
        text-align: center;
        text-decoration: none;
        color: black;
        margin: auto;
        display: block; }
    .info_insta {
        background-color: rgb(240, 240, 240);
        font-weight: 600;
        padding: 20px 0;
        display: flex;
        justify-content: space-around; }

    ul {
        padding: 0.8em 3em; }
    .card-inset {
        margin: 0.5em 1em;
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.25);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 20px;
        padding: 0.75em 1.5em;
        h3 {
            text-decoration: underline; } }
    .flex-card {
        display: flex;
        .w50 {
            width: 50vw !important; } }

    h2 {
        padding: 0.75em 0; }
    .flex-button {
        display: flex;
        button {
            font-weight: 600;
            color: white;
            font-size: 20px;
            background: $bleu-canard;
            border-radius: 20px;
            border: none;
            padding: 20px 20px;
            margin: 10px 15px; } } }



