@import "../../variables";

.single_msg_1 {
    display: flex;
    padding: 10px 20px 10px 20px;
    .profile {
        width: 80px;
        height: 80px; }
    .profile_preview {
        padding: 0 20px;
        &_title {
            font-size: 20px;
            font-weight: 600; }
        .ligne {
            align-items: center;
            justify-content: {}
            width: 10%;
            span {
                color: green;
                font-size: 50px;
                height: 75px; } } } }

.msg_input {
    background: #fff;
    position: fixed;
    bottom: 83px;
    width: 100%;
    img {
        width: 10vw;
        height: 5vh;
        margin: 1vw; }
    textarea {
        margin: 10px;
        padding: 0;
        width: 75vw;
        background: #F4F4F4;
        border: 1px solid rgba(54, 54, 54, 0.2);
        border-radius: 15px;
        resize: none; } }

.bulle {
    padding-bottom: 25%;
    color: #fff;
    &_send, &_recept {
        width: 100%;
        display: flex;
        &_1, &_2 {
            padding: 10px;
            width: 60%;
            margin: 10px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 20px; }
        &_1 {
            background: #93C8C8; }
        &_2 {
            background: #02C5C0; } }
    &_recept {
        justify-content: end; } }

