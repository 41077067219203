@import "../../variables";

.all_msg {
    &_single1 {
        margin: 10px 20px;
        border-bottom: 1px solid grey;
        padding: 10px 0;
        display: flex;
        .profile {
            width: 80px;
            height: 80px; }
        .profile_preview {
            padding: 10px 10px;
            &_title {
                font-size: 17px;
                font-weight: 550; }
            .d-flex {
                width: 60vw;
                justify-content: space-between;
                padding: 10px 0;
                p:nth-last-child(1) {
                    font-size: 17px; } } } } }


